import React from 'react';
import PropTypes from 'prop-types'

import {AuthProvider} from './auth-context'
import {CloseProvider} from './close-context'
import {TextProvider} from './text-context'
import {ToastProvider} from './toast-context'
import {UserProvider} from './user-context'

function AppProviders({children}) {
  return (
    <CloseProvider>
      <AuthProvider>
        <UserProvider>
          <TextProvider>
            <ToastProvider>{children}</ToastProvider>
          </TextProvider>
        </UserProvider>
      </AuthProvider>
    </CloseProvider>
  )
}

AppProviders.propTypes = {
  children: PropTypes.node,
}

export default AppProviders
