import React from 'react';
import {keyframes} from '@emotion/react'
import {AiOutlineLoading} from 'react-icons/ai'

const spin = keyframes({
  '0%': {transform: 'rotate(0deg)'},
  '100%': {transform: 'rotate(360deg)'},
})

export function Spinner(props) {
  return (
    <AiOutlineLoading
      css={{animation: `${spin} 1s linear infinite`}}
      aria-label="loading"
      {...props}
    />
  )
}

export function FullPageSpinner() {
  return (
    <div
      css={{
        marginTop: '3em',
        fontSize: '4em',
        textAlign: 'center',
        flexGrow: 1,
      }}
    >
      <Spinner />
    </div>
  )
}
