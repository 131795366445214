import React from 'react';
import * as theme from 'styles/theme'

import {css} from '@emotion/react'
import {transparentize} from 'polished'

export const flexCenter = css`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const mainPad = css`
  padding: ${theme.spacing.medium}px;
  max-width: ${theme.config.maxWidth}px;
  margin: auto;
  width: 100%;
  flex-grow: 1;

  ${theme.mq.tablet} {
    padding: ${theme.spacing.large}px;
  }

  ${theme.mq.desktop} {
    padding-left: ${theme.spacing.xlarge}px;
    padding-right: ${theme.spacing.xlarge}px;
    padding-bottom: ${theme.spacing.xlarge}px;
  }
`

export const disguise_ul = css`
  list-style-type: none;
  padding-left: 0;
`

export const spacing = (size = 'medium') => css`
  > * + * {
    margin-top: ${theme.spacing[size]}px;
  }
`

export const spacingHorizontal = (size = 'medium') => css`
  > * + * {
    margin-left: ${theme.spacing[size]}px;
  }
`

export function border(
  color,
  width = theme.borderWeights.medium,
  style = 'solid',
  side = null,
) {
  const modifier = side ? side.charAt(0).toUpperCase() + side.slice(1) : ''

  return {
    [`border${modifier}Width`]: width,
    [`border${modifier}Style`]: style,
    [`border${modifier}Color`]: color,
  }
}

export function borderTop(
  color,
  width = theme.borderWeights.medium,
  style = 'solid',
) {
  return border(color, width, style, 'top')
}

export function borderBottom(
  color,
  width = theme.borderWeights.medium,
  style = 'solid',
) {
  return border(color, width, style, 'bottom')
}

export function borderRight(
  color,
  width = theme.borderWeights.medium,
  style = 'solid',
) {
  return border(color, width, style, 'right')
}

export function borderLeft(
  color,
  width = theme.borderWeights.medium,
  style = 'solid',
) {
  return border(color, width, style, 'left')
}

export const formValidity = {
  focus: css`
    border: ${theme.borderWeights.medium}px solid ${theme.colors.iris} !important;
    box-shadow: 0 0 0 4px ${transparentize(0.8, theme.colors.iris)};
  `,
  invalid: css`
    border: ${theme.borderWeights.medium}px solid ${theme.colors.fuschia};
    box-shadow: 0 0 0 4px ${transparentize(0.8, theme.colors.fuschia)};
  `,
  valid: css`
    border: ${theme.borderWeights.medium}px solid ${theme.colors.evergreen};
    box-shadow: 0 0 0 4px ${transparentize(0.8, theme.colors.evergreen)};
  `,
  disabled: css`
    border-color: transparent;
    box-shadow: none;
  `,
}

export const shadow = {
  alpha: `inset 0px -1px ${transparentize(0.6, theme.colors.onyx)}`,
  beta: `0px 6px 2px -4px ${transparentize(0.9, theme.colors.onyx)}`,
  gamma: `0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04)`,
  delta: `0px 1px 3px rgba(0, 0, 0, 0.2)`,
}

export const cardStyle = css`
  background: #fff;
  box-sizing: border-box;
  border-radius: ${theme.radius.medium}px;
  box-shadow: ${shadow.delta};
  padding: 20px;
`

export const flexGap = gap => css`
  margin: ${-1 * gap}px 0 0 ${-1 * gap}px;
  width: calc(100% + ${gap}px);

  > * {
    margin: ${gap}px 0 0 ${gap}px;
  }
`

export const srOnly = css`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
`
