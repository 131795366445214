import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';

const CloseContext = createContext()

export function CloseProvider(props) {
  const [modalRegistry, setModalRegistry] = useState([])

  useEffect(() => {
    if (modalRegistry.length === 0) return
    const {refs, cb, customDoc} = modalRegistry[modalRegistry.length - 1]

    const handleClickOutside = event => {
      if (clickedOnScrollbar(event, customDoc)) return
      for (let ref of refs) {
        if (ref.current && ref.current.contains(event.target)) return
      }
      cb()
    }

    const handleKey = event => {
      if (event.key === 'Escape') cb()
    }

    document.addEventListener('mousedown', handleClickOutside)
    window.addEventListener('keydown', handleKey)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      window.removeEventListener('keydown', handleKey)
    }
  }, [modalRegistry])

  const value = useMemo(
    () => ({
      register: modal => setModalRegistry(old => old.concat(modal)),
      unregister: id =>
        setModalRegistry(old => old.filter(item => item.id !== id)),
    }),
    [],
  )

  return <CloseContext.Provider value={value} {...props} />
}

var currentId = 0

export default function useClose(refs, cb, isOpen = true, customDoc) {
  const context = useContext(CloseContext)
  if (context === undefined) {
    throw new Error(`useClose must be used within a CloseProvider`)
  }

  const idRef = useRef(currentId++)

  useEffect(() => {
    if (!isOpen) return
    const id = idRef.current
    context.register({refs, cb, id, isOpen, customDoc: customDoc?.current})
    return () => {
      context.unregister(id)
    }
  }, [cb, context, refs, isOpen, customDoc])
}

const clickedOnScrollbar = (e, doc = document.documentElement) =>
  doc.clientWidth <= e.clientX || doc.clientHeight <= e.clientY
