import 'typeface-assistant';
import 'typeface-changa';
import './styles/global.css';

import * as serviceWorker from './serviceWorker';

import React from 'react';
import App from './app';
import AppProviders from './context';
// import ReactGA from 'react-ga4';
import {createRoot} from 'react-dom/client';
import {BrowserRouter as Router} from 'react-router-dom';

// Initialize React Ga with your tracking ID
// ReactGA.initialize('G-46WZPRCEZ0');

const version = process.env.REACT_APP_VERSION;
document.documentElement.setAttribute('data-trestle', version);
const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <AppProviders>
    <Router>
      <App />
    </Router>
  </AppProviders>,
);

serviceWorker.unregister();
