import React from 'react';
import * as theme from 'styles/theme'
import * as typography from 'styles/typography'

import {darken, lighten, tint} from 'polished'

import {css} from '@emotion/react'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'
import {shadow} from 'styles/mixins'
import {Spinner} from '../misc/Spinner'

function Button({
  children,
  onClick = () => {},
  submit,
  isLoading,
  disabled = false,
  type = 'primary',
  color = theme.colors.iris,
  ...buttonProps
}) {
  return (
    <button
      type={submit ? 'submit' : 'button'}
      onClick={onClick}
      {...buttonProps}
      disabled={disabled}
      css={[
        theme.rounded,
        typography.button,
        buttonStyles(color),
        type === 'secondary' && secondaryStyles(color),
        type === 'tertiary' && tertiaryStyles(color),
        disabled && disabledStyles,
      ]}
    >
      {children}{' '}
      {isLoading && <Spinner css={{marginLeft: theme.spacing.xsmall}} />}
    </button>
  )
}

// link that is styled like a button
export function ButtonLink({
  children,
  to,
  type = 'primary',
  newPage = false,
  color = theme.colors.iris,
  ...linkProps
}) {
  const styles = [
    typography.button,
    buttonStyles(color),
    type === 'secondary' && secondaryStyles(color),
    type === 'tertiary' && tertiaryStyles(color),
    theme.rounded,
  ]
  if (newPage)
    return (
      <a
        href={to}
        rel="noreferrer noopener"
        target="_blank"
        css={styles}
        {...linkProps}
      >
        {children}
      </a>
    )

  return (
    <Link to={to} {...linkProps} css={styles}>
      {children}
    </Link>
  )
}

const buttonStyles = color => css`
  cursor: pointer;
  border: none;
  padding: 12px 24px;
  line-height: 1.5;
  color: ${theme.colors.white};
  box-shadow: ${shadow.alpha}, ${shadow.beta};
  background-color: ${color};
  display: inline-block;

  &:hover:not(:disabled) {
    background-color: ${lighten(0.05, color)};
  }

  &:active:not(:disabled) {
    background-color: ${darken(0.05, color)};
  }
`

const secondaryStyles = color => css`
  color: ${color};
  background-color: ${tint(0.75, color)};
  box-shadow: none;

  &:hover:not(:disabled) {
    background-color: ${tint(0.8, color)};
  }

  &:active:not(:disabled) {
    background-color: ${tint(0.7, color)};
  }
`

const tertiaryStyles = color => css`
  color: ${color};
  background-color: transparent;
  box-shadow: none;
  border: ${theme.borderWeights.medium}px solid ${tint(0.9, color)};
  padding: ${12 - theme.borderWeights.medium}px
    ${24 - theme.borderWeights.medium}px;

  &:hover:not(:disabled) {
    background-color: ${tint(0.95, color)};
  }

  &:active:not(:disabled) {
    background-color: ${tint(0.9, color)};
  }
`

const disabledStyles = css`
  opacity: 0.4;
`

Button.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  submit: PropTypes.bool,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  type: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
  color: PropTypes.string,
}

ButtonLink.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string,
  type: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
  newPage: PropTypes.bool,
  color: PropTypes.string,
}

export default Button
