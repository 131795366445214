export default {
  onboarding: {
    welcome: {
      support: {
        header:
          "We're excited to have you! There are a couple of things we need you to complete in order to finalize your profile.",
        links: {
          profile: {
            title: {
              incomplete: 'Complete Profile',
              complete: 'Your profile is complete!',
            },
            subtitle: {
              incomplete: 'Click to start',
              complete: 'Click to edit',
            },
          },
          youth: {
            title: {
              incomplete: "Complete {youth_name}'s profile.",
              complete: "{youth_name}'s profile is complete!",
            },
            subtitle: {
              incomplete: 'Click to start',
              complete: 'Click to edit',
            },
            label: "Please also complete {youth_name}'s profile",
          },
        },
      },
    },
    profile: {
      header: "Let's learn a bit more about you.",
      prefix: {
        label: 'What title do you like to be addressed with?',
      },
      title: {
        label: 'What is your school job title?',
        description:
          'This will be displayed to give other team members a better understanding of how you are interacting with a youth in the school setting.',
        placeholder: 'School Title',
      },
      communityTitle: {
        label: 'What is your role with the youth?',
        description:
          'This will be displayed to give other team members a better understanding of how you are interacting with the youth in the community setting.',
        placeholder: 'Role with Youth',
      },
      homeTitle: {
        label: 'Who are you to the youth?',
        description:
          'This will be displayed to give other team members a better understanding of how you are interacting with the youth in the home setting.',
        placeholder: 'Role with Youth',
      },
      adminTitle: {
        label: 'What is your title?',
        description:
          'This will be displayed to give the youth and their team members a better understanding of how you are interacting with them on the app.',
        placeholder: 'Title',
      },
      phone: {
        label: 'What is your phone number?',
        description:
          'This will be displayed on your profile and in network pages in case guardians need to get in contact.  However, they will be prompted to email you first unless a phone call/text is necessary.',
        placeholder: '300-400-5060',
      },
      description: {
        label: 'Write a small paragraph (500 words or less) about yourself.',
        description:
          'This will be displayed on your profile and will give guardians and youth some information about you.',
        placeholder: 'About yourself...',
      },
      descriptionHome: {
        label: 'Write about your role with {youth_name}.',
        description:
          'Describe two to three transition related activities that you do with {youth_name}.',
        placeholder: 'What I do with {youth_name}...',
      },
      preferredCommunication: {
        label: 'What is your preferred means of communication?',
        description: 'In case a team member needs to contact you.',
      },
      preferredTime: {
        label: 'when is the best time to get in touch with you?',
      },
      phoneHomePrimary: {
        label: 'What is your phone number?',
      },
      pronouns: {
        label: 'What are your pronouns?',
        description: 'Add your pronouns to your profile.',
      },
    },
    youth: {
      header: "Let's learn a bit more about {youth_name}.",
      nickname: {
        label: 'Do I use a nickname? (optional)',
        description: 'If {youth_name} is fine, leave this field empty.',
        placeholder: 'Nickname',
      },
      birthday: {
        label: 'When is my birthday?',
        description:
          "We don't want to miss the most important day of the year!",
        placeholder: 'April 1st',
      },
      health: {
        label: 'Health and Safety',
        description: 'Important information others should know about me',
        placeholder: 'You should know this about me...',
      },
      covid_skills: {
        label: 'Covid Skills',
        description: 'Select all that apply. I need help with...',
      },
      strengths: {
        label: 'Strengths',
        description: 'What am I really good at?',
        placeholder: 'I am really good at...',
      },
      interests: {
        label: 'Interests',
        description: 'What do I like?',
        placeholder: 'I like...',
      },
      reinforcers: {
        label: 'Reinforcers',
        description: 'What do I enjoy?',
        placeholder: 'I enjoy it when you...',
      },
      communication: {
        label: 'Communication',
        description: 'How do I communicate? When do I communicate best?',
        placeholder: 'I communicate by... I communicate best when...',
      },
      unique: {
        label: 'Uniquely Me',
        description: 'What makes me unique?',
        placeholder: 'This is what makes me unique...',
      },
      behaviors: {
        label: 'Behaviors',
        description: 'Add my tendencies, triggers, and how to help.',
        tendency: {
          label: 'Sometimes I might...',
          placeholder: 'Sometimes I might...',
        },
        trigger: {
          label: 'When these triggers occur...',
          placeholder: 'When these triggers occur this happens...',
        },
        solution: {
          label: 'The best way to help is...',
          placeholder: 'The best way to help when this happens is...',
        },
      },
      dreams: {
        label: 'Dreams',
        description: 'The biggest goal for my transition this year is?',
        placeholder: 'The biggest goal for my transition this year is...',
      },
    },
    setPassword: {
      header: "Let's finish creating your account",
    },
  },
  network: {
    home: {
      title: "{youth_name}'s Network",
      subtitle:
        'See who {youth_name} is connected with on Trestle. Click to view their profile.',
    },
    school: {
      title: 'Your Network',
      subtitle: 'See the youth in your network. Click to view a profile.',
    },
  },
  profile: {
    about: {
      youth: {
        health_and_safety: {
          label: 'Health and Safety',
          description: 'Important information others should know about me',
        },
        covid_skills: {
          label: 'Covid Skills',
          description: 'I need help with...',
        },
        strengths: {
          label: 'Strengths',
          description: 'What am I really good at?',
        },
        interests: {
          label: 'Interests',
          description: 'What do I like?',
        },
        reinforcers: {
          label: 'Reinforcers',
          description: 'What do I enjoy?',
        },
        communication: {
          label: 'Communication',
          description: 'How do I communicate? When do I communicate best?',
        },
        uniquely_me: {
          label: 'Uniquely Me',
          description: 'What makes me unique?',
        },
        behaviors: {
          label: 'Behaviors',
          description: 'My tendencies, triggers, and how to help.',
          tendency: {
            label: 'Sometimes I might...',
          },
          trigger: {
            label: 'When these triggers occur...',
          },
          solution: {
            label: 'The best way to help is...',
          },
        },
        dreams: {
          label: 'Dreams',
          description: 'The biggest goal for my transition this year is?',
          placeholder:
            "My biggest goal for my youth's transition this year is... My youth's biggest goal for their transition this year is...",
        },
      },
      home: {
        description: {
          label: 'About',
        },
        prefix: {
          label: 'Title',
        },
        title: {
          label: 'Role',
        },
        preferred_communication: {
          label: 'Preferred Means of Communication',
        },
        preferred_time: {
          label: 'Best time to get in touch',
        },
      },
    },
    activities: {
      longterm_outcomes: {
        title: 'Longterm Outcomes',
      },
      current_activities: {
        title: 'Current Activities',
        description:
          'Here are the activities you and {youth_name} are currently working on.',
        add_activity_label: 'Add another activity for {youth_name}',
      },
      past_activities: {
        title: 'Past Activities',
        description: 'These are activities that have already been completed.',
      },
    },
  },
  surveys: {
    past: {
      hasPast:
        'You can take surveys as many times as you would like. Surveys allow us to collect feedback on who you interact with within your network. Please take the survey weekly or as many times as you like during the week.',
      empty:
        "You haven't taken a survey yet. Surveys allow us to collect feedback on who you interact with within your network. Please take the survey weekly or as many times as you like during the week.",
    },
    startButton: 'Add A Survey',
    completedRecently: {
      false: 'Please take a survey soon',
      true: "You've completed a survey recently",
    },
  },
  forgotPassword: {
    header: 'Forgot Password',
    body: "Please enter your email address and we'll send you instructions on how to reset your password.",
    success:
      'Request sent. If an account exists with that email, it should receive instructions in the next few minutes.',
  },
  resetPassword: {
    header: 'Reset Password',
    invalidToken:
      'This url is invalid. Try resetting your password again here:',
  },
};
