import React from 'react';
import Rollbar from 'rollbar'

const RollbarErrorTracking = (() => {
  const RollbarObj = new Rollbar({
    accessToken: process.env.REACT_APP_ROLLBAR_TOKEN,
    captureUncaught: false,
    captureUnhandledRejections: false,
    payload: {
      environment:
        process.env.NODE_ENV === 'production' ? 'production' : 'local',
    },
  })
  return RollbarObj
})()

export default RollbarErrorTracking
