import React from 'react';
const abortController = new AbortController()
if (process.env.NODE_ENV !== 'test') {
  window.onbeforeunload = function () {
    abortController.abort()
  }
}

function client(endpoint, {body, params, id, ...customConfig} = {}) {
  const token = window.localStorage.getItem('__auth_token__')
  const headers = {'Content-Type': 'application/json'}
  if (token) {
    headers.Authorization = `Bearer ${token}`
  }
  const config = {
    method: endpoint.method ?? (body ? 'POST' : 'GET'),
    signal: abortController.signal,
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  }
  if (body) {
    config.body = JSON.stringify(body)
  }

  const encodedParams =
    params &&
    Object.entries(params)
      .map(([key, val]) => (val ? `${key}=${val}` : ''))
      .join('&')

  return window
    .fetch(
      `${process.env.REACT_APP_API_URL}${endpoint.url ?? endpoint}${
        id ? '/' + encodeURIComponent(id) : ''
      }${params ? '?' + encodedParams : ''}`,
      config,
    )
    .then(async response => {
      const data = await response.json()
      if (response.ok) {
        return data
      } else {
        return Promise.reject({...data, response})
      }
    })
}

export default client
