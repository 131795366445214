import React from 'react';
import * as theme from './theme'

import {css} from '@emotion/react'

export const h1 = css`
  font-family: ${theme.fonts.family.primary};
  font-weight: ${theme.fonts.weights.bold};
  font-size: ${theme.fonts.size.xxlarge / 16}rem;
  letter-spacing: ${(theme.fonts.size.xxlarge / 16) * -0.04}rem;
  color: ${theme.colors.onyx};
`

export const h2 = css`
  font-family: ${theme.fonts.family.primary};
  font-weight: ${theme.fonts.weights.bold};
  font-size: ${theme.fonts.size.xlarge / 16}rem;
  letter-spacing: ${(theme.fonts.size.xlarge / 16) * -0.02}rem;
  color: ${theme.colors.onyx};
`
export const h3 = css`
  font-family: ${theme.fonts.family.primary};
  font-weight: ${theme.fonts.weights.bold};
  font-size: ${theme.fonts.size.large / 16}rem;
  letter-spacing: ${(theme.fonts.size.large / 16) * -0.02}rem;
  color: ${theme.colors.onyx};
`

export const h4 = css`
  font-family: ${theme.fonts.family.primary};
  font-weight: ${theme.fonts.weights.bold};
  font-size: ${20 / 16}rem;
  color: ${theme.colors.onyx};
`

export const body = css`
  font-family: ${theme.fonts.family.secondary};
  font-weight: ${theme.fonts.weights.medium};
  font-size: ${theme.fonts.size.medium / 16}rem;
  color: ${theme.colors.slate};
  line-height: 1.4;

  strong,
  &strong,
  &.bold {
    color: ${theme.colors.onyx};
    font-weight: ${theme.fonts.weights.bold};
  }
`

export const bodyLarge = css`
  ${body}

  font-size: ${18 / 16}rem;
`

export const small = css`
  font-family: ${theme.fonts.family.secondary};
  font-weight: ${theme.fonts.weights.medium};
  font-size: ${theme.fonts.size.small / 16}rem;
  color: ${theme.colors.onyx};
`

export const preTitle = css`
  font-family: ${theme.fonts.family.secondary};
  font-weight: ${theme.fonts.weights.bold};
  font-size: ${theme.fonts.size.small / 16}rem;
  letter-spacing: ${(theme.fonts.size.xsmall / 16) * 0.03}rem;
  text-transform: uppercase;
  color: ${theme.colors.slate};
`

export const button = css`
  font-family: ${theme.fonts.family.secondary};
  font-weight: ${theme.fonts.weights.bolder};
  font-size: ${theme.fonts.size.medium / 16}rem;
  letter-spacing: ${(theme.fonts.size.medium / 16) * 0.03}rem;
  color: ${theme.colors.onyx};
  text-transform: uppercase;
`

export const link = css`
  font-family: ${theme.fonts.family.secondary};
  font-weight: ${theme.fonts.weights.bold};
  font-size: ${theme.fonts.size.medium / 16}rem;
  letter-spacing: ${(theme.fonts.size.medium / 16) * 0.03}rem;
  color: ${theme.colors.iris};
  text-decoration: underline;
`
