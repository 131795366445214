import React, { createContext, useContext, useState } from 'react';

import PropTypes from 'prop-types';
import Toaster from '../components/misc/Toasts';

const ToastContext = createContext()

function ToastProvider(props) {
  const [toasts, setToasts] = useState([])

  const addToast = options => {
    const newToast = createToast(options)
    setToasts(toasts => [...toasts, newToast])
    setTimeout(() => {
      removeToast(newToast.id)
    }, newToast.duration)
  }

  const removeToast = id => {
    setToasts(toasts => toasts.filter(toast => toast.id !== id))
  }

  return (
    <ToastContext.Provider {...props} value={{addToast, removeToast}}>
      {props.children}
      <Toaster toasts={toasts} />
    </ToastContext.Provider>
  )
}

ToastProvider.propTypes = {
  children: PropTypes.node,
}

function useToast() {
  const context = useContext(ToastContext)
  if (context === undefined) {
    throw new Error(`useTost must be used within a ToastProvider`)
  }
  return context
}

export { ToastProvider, useToast };

// mini toast factory

let id = 0

const defaultOptions = {
  text: '',
  type: 'success',
  duration: 4000,
}

function createToast(options) {
  return {
    ...defaultOptions,
    ...options,
    id: id++,
  }
}
