import React from 'react';
import Button, {ButtonLink} from 'components/form/Button'

import styled from '@emotion/styled'
import { Component } from 'react';
import {mainPad} from 'styles/mixins'
import {spacing} from 'styles/theme'
import RollbarErrorTracking from 'utils/rollbar'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  align-items: center;
  justify-content: center;

  > * + * {
    margin-bottom: ${spacing.medium}px;
  }

  text-align: center;
`

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = {error: null, errorInfo: null}
  }

  componentDidCatch(error, errorInfo) {
    if (process.env.NODE_ENV === 'development') {
      throw error
    }

    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    })

    // log error messages to error reporting service
    RollbarErrorTracking.error(error)
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <main
          css={[
            mainPad,
            {
              display: 'flex',
              height: '100%',
              flexDirection: 'column',
              '> *:not(header,footer)': {flex: 1},
            },
          ]}
        >
          <Wrapper>
            <h1 css={{fontSize: '6rem', lineHeight: 1}}>Oops!</h1>
            <h3>An error has occured</h3>
            <p css={{maxWidth: 240}}>
              This issue has been reported. Apologies for the inconvenience!
            </p>
            <p>
              <ButtonLink to="/" css={{marginRight: spacing.small}}>
                go home
              </ButtonLink>
              <Button onClick={() => location.reload()}>reload page</Button>
            </p>
          </Wrapper>
        </main>
      )
    }
    // Normally, just render children
    // eslint-disable-next-line react/prop-types
    return this.props.children
  }
}

export default ErrorBoundary
