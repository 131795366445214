import React from 'react';
import {queryCache} from 'react-query'

import client from './api-client'

import endpoints from 'config/endpoints'

const localStorageKey = '__auth_token__'

function getToken() {
  return window.localStorage.getItem(localStorageKey)
}

function removeToken() {
  window.localStorage.clear()
  return Promise.resolve()
}

function setToken(token) {
  window.localStorage.setItem(localStorageKey, token)
}

function login({email, password}) {
  return client(endpoints.login, {body: {email, password}}).then(
    ({user, access_token}) => {
      setToken(access_token)
      return user
    },
  )
}

// Might want to come up with some better names for the following 2 functions:

// 'Activate' is for when a user arrives on the site via an activate url (sent via email).
function activate(token) {
  return client(endpoints.activate, {
    headers: {Authorization: `Bearer ${token}`},
  }).then(({user}) => {
    return user
  })
}

// 'signUp' is for when a user inputs their password as part of the first step of the onboarding phase.
// if the user successfully sets their password, the full user object and fresh token are returned,
//  and its as if they logged in normally (via email/password)
function register({token, password}) {
  return client(endpoints.register, {
    body: {password},
    headers: {Authorization: `Bearer ${token}`},
  }).then(({user, access_token}) => {
    setToken(access_token)
    return user
  })
}

function logout() {
  queryCache.clear()
  client(endpoints.logout).catch(() => {})
  return removeToken()
}

async function getUser() {
  const token = getToken()

  if (!token) {
    return client(endpoints.ping)
      .then(() => ({user: null, serverError: false}))
      .catch(() => ({user: null, serverError: true}))
  }

  return await client(endpoints.getMe)
    .then(({user}) => ({user, serverError: false}))
    .catch(e => {
      if (e.name !== 'AbortError') logout()
      return Promise.resolve({
        user: null,
        serverError: e?.response?.status === 500 ?? true,
      })
    })
}

export {getToken, login, logout, getUser, localStorageKey, activate, register}
