import React from 'react';
const POST = 'POST'
const GET = 'GET'
const PUT = 'PUT'
const DELETE = 'DELETE'

export default {
  ping: {
    url: 'ping',
    method: 'get',
  },

  // AUTH
  login: {
    url: 'auth/login',
    method: POST,
  },
  logout: {
    url: 'auth/logout',
    method: POST,
  },
  activate: {
    url: 'users/register',
    method: GET,
  },
  register: {
    url: 'users/register',
    method: POST,
  },
  getMe: {
    url: 'auth/me',
    method: GET,
  },
  forgotPassword: {
    url: 'auth/forgot',
  },
  resetPassword: {
    url: 'auth/set-password',
    method: PUT,
  },

  // YOUTH
  updateYouth: {
    url: 'youth/update',
    method: PUT,
  },
  getYouthNetwork: {
    url: 'youth/network',
    method: GET,
  },
  getYouth: {
    url: 'youth',
    method: GET,
  },
  getYouthDashboard: {
    url: 'youth/dashboard',
    method: GET,
    urlParam: 'youth_id',
  },

  // USERS
  setPassword: {
    url: 'users/set-password',
    method: PUT,
  },
  getAllUsers: {
    url: 'users/all',
    method: GET,
  },
  updateUser: {
    url: 'users',
    method: PUT,
  },
  updateProfile: {
    url: 'users/profile',
    method: PUT,
  },
  updateNotifications: {
    url: 'users/profile',
    method: PUT,
  },
  getUser: {
    url: 'users',
    method: GET,
    urlParam: 'username',
  },
  deleteUser: {
    url: 'users',
    method: DELETE,
    user: 'id',
  },
  createUser: {
    url: 'users',
    method: POST,
  },
  searchUsers: {
    url: 'users/search',
    method: GET,
  },
  setUserStatus: {
    url: 'users/status',
    method: PUT,
  },
  setAllStatus: {
    url: 'users/status/all',
    method: PUT,
  },
  exportAllUsers: {
    url: 'users/export/all',
    method: PUT,
  },
  exportSchoolUsers: {
    url: 'users/export/school',
    method: PUT,
  },
  exportDistrictUsers: {
    url: 'users/export/district',
    method: PUT,
  },
  exportUserActivities: {
    url: 'export/users',
    method: GET,
  },
  exportActivities: {
    url: 'export/activities',
    method: GET,
  },
  exportCompletions: {
    url: 'export/completions',
    method: GET,
  },
  activateUser: {
    url: 'users/activate',
    method: POST,
  },

  // ONBOARDING
  completeOnboarding: {
    url: 'onboarding/complete',
    method: POST,
  },
  inviteByRole: {
    url: 'onboarding/invite',
    method: POST,
  },
  inviteUser: {
    url: 'onboarding/invite',
    method: POST,
    urlParam: 'username',
  },
  resendInvite: {
    url: 'onboarding/resend',
    method: POST,
    urlParam: 'username',
  },

  // NETWORK
  getNetwork: {
    url: 'networks',
    method: GET,
  },
  getNetworkForUsername: {
    url: 'networks',
    method: GET,
    urlParam: 'username',
  },
  removeUserFromNetwork: {
    url: 'networks/remove',
    method: DELETE,
  },
  addToNetwork: {
    url: 'networks/add',
    method: POST,
  },

  // FEEDBACK
  getFeedback: {
    url: 'feedbacks',
    method: GET,
  },
  createFeedback: {
    url: 'feedbacks',
    method: POST,
  },
  deleteFeedback: {
    url: 'feedbacks',
    method: DELETE,
  },

  // TIMELINE
  getYouthTimeline: {
    url: 'timelines',
    method: GET,
    urlParam: 'userId',
  },
  getMyTimeline: {
    url: 'timelines',
    method: GET,
  },

  // ACTIVITIES
  getActivities: {
    url: 'activities',
    method: GET,
  },
  getActivity: {
    url: 'activities',
    method: GET,
    urlParam: 'id',
  },
  updateActivity: {
    url: 'activities',
    method: PUT,
    urlParam: 'id',
  },
  deleteActivity: {
    url: 'activities',
    method: DELETE,
    urlParam: 'id',
  },
  createActivities: {
    url: 'activities',
    method: POST,
  },

  // COMPLETIONS
  createCompletion: {
    url: 'completions',
    method: POST,
  },

  // CATEGORIES
  createCategory: {
    url: 'categories',
    method: POST,
  },
  removeCategory: {
    url: 'categories',
    method: DELETE,
    urlParam: 'id',
  },
  updateCategory: {
    url: 'categories',
    method: PUT,
    urlParam: 'id',
  },
  getAllCategories: {
    url: 'categories',
    method: GET,
  },
  searchCategories: {
    url: 'categories/search',
    method: GET,
  },

  // RESOURCES
  getResources: {
    url: 'resources/category',
    method: GET,
  },
  getResource: {
    url: 'resources/category',
    method: GET,
    urlParam: 'id',
  },
  updateResource: {
    url: 'resources',
    method: PUT,
    urlParam: 'id',
  },
  toggleResourceComplete: {
    url: 'resources/complete',
    method: PUT,
    urlParam: 'id',
  },
  getAllResources: {
    url: 'resources',
    method: GET,
  },
  createResource: {
    url: 'resources',
    method: POST,
  },
  deleteResource: {
    url: 'resources',
    method: DELETE,
    urlParam: 'id',
  },

  // SCHOOLS / DISTRICTS
  getDistricts: {
    url: 'districts',
    method: GET,
  },
  getDistrict: {
    url: 'districts/show',
    method: GET,
    urlParam: 'id',
  },
  getSchool: {
    url: 'schools/show',
    method: GET,
    urlParam: 'id',
  },
  createDistrict: {
    url: 'districts',
    method: POST,
  },
  createSchool: {
    url: 'schools',
    method: POST,
  },
  updateDistrict: {
    url: 'districts',
    method: PUT,
    urlParam: 'id',
  },
  deleteDistrict: {
    url: 'districts',
    method: DELETE,
    urlParam: 'id',
  },

  // SURVEYS
  getMySurveys: {
    url: 'survey',
    method: GET,
  },
  getSurveyQuestions: {
    url: 'survey/questions',
    method: GET,
  },
  submitSurveys: {
    url: 'survey',
    method: POST,
  },
  exportAllSurveys: {
    url: 'survey/export',
    method: GET,
  },
  exportSchoolSurveys: {
    url: 'survey/export/school',
    method: GET,
  },
  exportYouthSurveys: {
    url: 'survey/export',
    method: GET,
    urlParam: 'id',
  },
}
