import * as theme from 'styles/theme';
import * as typography from 'styles/typography';

import {css} from '@emotion/react';
import {normalize} from 'polished';

export default css`
  ${normalize()}
  * {
    box-sizing: border-box;
  }

  html {
    font-size: 100%;
    ${'' /* overflow: hidden; */}
  }

  #root,
  body,
  html {
    height: 100%;
  }

  #root {
    ${'' /* overflow-y: scroll; */}
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul {
    margin: 0;
  }

  hr {
    margin: ${theme.spacing.medium}px 0;
    border: 0;
    height: ${theme.borderWeights.thin}px;
    background: ${theme.colors.border};
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  body {
    ${typography.body}

    background: ${theme.colors.bg};

    &::after {
      content: '';
      position: absolute;
      z-index: ${theme.depths.header + 1};
      height: 4px;
      top: 0;
      left: 0;
      right: 0;
      background: linear-gradient(
        90deg,
        #ff8f50 0%,
        #ec2a70 21.34%,
        #9e2ce3 41.93%,
        #3b4ef5 61.46%,
        #34c37f 81.77%,
        #ced20f 100%
      );
    }

    ${
      process.env.REACT_APP_STAGING_ENV === 'true'
        ? css`
          &::before {
            content: 'staging';
            background: ${theme.colors.yellow};
            padding: 6px 12px;
            display: block;
            color: ${theme.colors.black};
            font-weight: bold;
            font-family: ${theme.fonts.family.primary};
            text-transform: uppercase;
            font-size: 1.2rem;
            height: 38px;
            box-sizing: border-box;
            text-align: right;
          }

          &::after {
            top: 38px;
          }
        `
        : null
    }
  }

  h1 {
    ${typography.h1}
  }

  h2 {
    ${typography.h2}
  }

  h3 {
    ${typography.h3}
  }

  h4 {
    ${typography.h4}
  }

  h5 {
    ${typography.preTitle}
  }

  .link {
    ${typography.link}
  }

  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  button:hover {
    cursor: pointer;
  }
`;
