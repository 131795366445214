import React from 'react';
import * as theme from 'styles/theme'

import {
  BlockRounded,
  CheckRounded,
  ErrorOutlineRounded,
} from '@material-ui/icons'

import {css} from '@emotion/react'
import styled from '@emotion/styled'
import {useToast} from 'context/toast-context'
import PropTypes from 'prop-types'

const icons = {
  warning: <ErrorOutlineRounded css={{color: theme.colors.yellow}} />,
  success: <CheckRounded css={{color: theme.colors.evergreen}} />,
  error: <BlockRounded css={{color: theme.colors.fuschia}} />,
}

export function Toast({text, type, id}) {
  const {removeToast} = useToast()
  return (
    <ToastWrapper type={type} onClick={() => removeToast(id)}>
      {type && icons[type]}
      <p>{text}</p>
    </ToastWrapper>
  )
}

Toast.propTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['warning', 'success', 'error']),
  id: PropTypes.number,
}

const ToastWrapper = styled.div`
  color: ${theme.colors.white};
  background-color: ${theme.colors.onyx};
  display: inline-flex;
  justify-content: flex-end;
  padding: 15px 20px;
  max-width: 400px;
  border-radius: 4px;
  margin: 4px 0;
  flex-direction: row;
  pointer-events: all;
  ${theme.rounded}

  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15), 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  border: 2px solid transparent;

  &:active {
    border-color: ${theme.colors.violet};
  }

  p {
    flex: 1;
    margin: 0;
    margin-left: 4px;
  }

  &.warning {
    background-color: yellow;
  }

  &.error {
    color: white;
    background-color: red;
  }

  &.success {
    color: white;
    background-color: green;
  }
`

export default function Toaster({toasts, position}) {
  return (
    <div css={ToastWrapperStyles} className={position}>
      {toasts.map(toast => (
        <Toast key={toast.id} {...toast} />
      ))}
    </div>
  )
}

Toaster.propTypes = {
  toasts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      text: PropTypes.string,
      type: PropTypes.oneOf(['warning', 'success', 'error']),
    }),
  ),
  position: PropTypes.oneOf([
    'top-left',
    'top-right',
    'bottom-left',
    'bottom-right',
  ]),
}

Toaster.defaultProps = {
  toasts: [],
  position: 'bottom-right',
}

const ToastWrapperStyles = css`
  position: fixed;
  display: flex;
  flex-direction: column;
  padding: 12px 16px;
  width: 100%;
  z-index: ${theme.depths.toast};
  pointer-events: none;

  &.top-left {
    left: 0;
    top: 0;
    flex-direction: column-reverse;
  }

  &.bottom-left {
    left: 0;
    bottom: 0;
  }

  &.top-right {
    right: 0;
    top: 0;
    flex-direction: column-reverse;
    align-items: flex-end;
  }

  &.bottom-right {
    right: 0;
    bottom: 0;
    align-items: flex-end;
  }
`
