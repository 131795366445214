import React from 'react';
import { createContext, useContext, useMemo } from 'react';

import textConf from 'config/text'
import {replaceAll} from 'utils/utils'

const TextContext = createContext()

function TextProvider(props) {
  const defaults = useMemo(
    () => ({
      youth_name: 'NONAME',
    }),
    [],
  )

  return <TextContext.Provider value={{text: textConf, defaults}} {...props} />
}

function useText(section = '', overrides = {}) {
  const {text: allText, defaults} = useContext(TextContext)
  if (allText === undefined) {
    throw new Error(`useText must be used within a TextProvider`)
  }

  return useMemo(() => {
    let textSection = {...getNestedProperty(allText, section)}
    const replacements = {...defaults, ...overrides}
    for (const [key, value] of Object.entries(replacements)) {
      var re = new RegExp(`{${key}}`, 'g')
      textSection = replaceAll(textSection, re, value)
    }
    return textSection
  }, [section, overrides, allText, defaults])
}

export {TextProvider, useText}

function getNestedProperty(obj, str) {
  if (str === '') return obj
  str = str.split('.')
  for (var i = 0; i < str.length; i++) obj = obj[str[i]]
  return obj
}
