export const colors = {
  // DEPRACATED COLORS - to be removed
  black: '#000000',
  smoke: '#c3cfd9',
  grey: '#788896',
  violet: '#6558f5',
  red: '#d3455b',
  green: '#1aae9f',
  yellow: '#F7C325',
  orange: '#E8833A',
  blue: '#2c88d9',

  // NEW COLORS
  fuschia: '#EC2A70',
  iris: '#3B4EF5',
  skyblue: '#81d9ff',
  pumpkin: '#FF8F50',
  lavender: '#9E2CE3',
  onyx: '#0e0e2c',
  evergreen: '#34C37F',
  slate: '#4a4a68',
  lightSlate: '#8c8ca1',
  dorian: '#ecf1f4',
  cloud: '#fafcfe',
  white: '#ffffff',
  border: '#dce1f1',
  bg: '#F1F2F5',
};

export const breakpoints = {
  tablet: 736,
  desktop: 1024,
  desktopLarge: 1440,
};

export const mq = {};

Object.keys(breakpoints).map(
  key => (mq[key] = `@media (min-width: ${breakpoints[key]}px)`),
);

export const spacing = {
  xsmall: 4,
  small: 8,
  medium: 16,
  large: 32,
  xlarge: 64,
};

export const depths = {
  header: 500,
  modal: 1000,
  toast: 2000,
};

export const fonts = {
  family: {
    primary: "'Changa', sans-serif",
    secondary: "'Assistant', sans-serif",
  },
  size: {
    xxsmall: 10,
    xsmall: 12,
    small: 14,
    medium: 16,
    large: 24,
    xlarge: 40,
    xxlarge: 64,
  },
  weights: {
    medium: 400,
    bold: 700,
    bolder: 800,
  },
};

export const rounded = {
  borderRadius: 12,
};

export const radius = {
  small: 8,
  medium: 12,
  large: 24,
};

export const borderWeights = {
  thin: 1,
  medium: 2,
  thick: 4,
};

export const config = {
  sidebarWidth: 300,
  maxWidth: 1250,
};
