import React, {Suspense, lazy} from 'react';
import {Redirect, Route, Switch, useLocation} from 'react-router-dom';

import {Global} from '@emotion/react';
import {ReactQueryConfigProvider} from 'react-query';
import ErrorBoundary from 'screens/ErrorBoundary';
import globalStyles from 'styles/global';
import {FullPageSpinner} from './components/misc/Spinner';
import {useAuth} from './context/auth-context';

const AuthenticatedApp = lazy(() => import('./authenticated-app'));
const UnauthenticatedApp = lazy(() => import('./unauthenticated-app'));
const Activate = lazy(() => import('./screens/Activate'));
const ServerDown = lazy(() => import('./screens/ServerDown'));
const Terms = lazy(() => import('./screens/Terms'));
const Privacy = lazy(() => import('./screens/Privacy'));
const ForgotPassword = lazy(() => import('./screens/ForgotPassword'));
const PasswordReset = lazy(() => import('./screens/PasswordReset'));

function App() {
  const {pathname} = useLocation();
  const {user, serverDown} = useAuth();

  //   useEffect(() => {
  //     console.log(pathname + search);
  //     ReactGA.send({
  //       hitType: 'pageview',
  //       page: '/my-path',
  //       title: 'Custom Title',
  //     });
  //   }, [location]);

  return (
    <ReactQueryConfigProvider
      config={{
        queries: {
          refetchOnWindowFocus:
            process.env.REACT_APP_MOCK_SERVER === 'true' ? false : true,
          cacheTime:
            process.env.REACT_APP_MOCK_SERVER === 'true' ? 1000 : 5 * 60 * 1000,
        },
      }}
    >
      <ErrorBoundary>
        <Suspense fallback={<FullPageSpinner />}>
          {serverDown ? (
            <ServerDown />
          ) : (
            <Switch>
              <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
              <Route path="/activate" component={Activate} />
              <Route path="/terms" component={Terms} />
              <Route path="/privacy-policy" component={Privacy} />
              <Route path="/forgot-password" component={ForgotPassword} />
              <Route path="/password-reset" component={PasswordReset} />
              <Route>
                {user ? <AuthenticatedApp /> : <UnauthenticatedApp />}
              </Route>
            </Switch>
          )}
        </Suspense>
      </ErrorBoundary>
      <Global styles={globalStyles} />
    </ReactQueryConfigProvider>
  );
}

export default App;
