import React from 'react';
import { createContext, useContext } from 'react';

import {useAuth} from './auth-context'

import {isAdmin} from 'config/user'

const UserContext = createContext()

function UserProvider(props) {
  // Abstract user data from auth provider
  const {user, setUser} = useAuth()

  const is_admin = isAdmin(user?.role?.id)

  return <UserContext.Provider value={{user, setUser, is_admin}} {...props} />
}

function useUser() {
  const context = useContext(UserContext)
  if (context === undefined) {
    throw new Error(`useUser must be used within a UserProvider`)
  }
  return context
}

export {UserProvider, useUser, UserContext}
