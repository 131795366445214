import * as authClient from '../utils/auth-client';

import React, { useEffect, useState } from 'react';

import { FullPageSpinner } from 'components/misc/Spinner';

const AuthContext = React.createContext()

function AuthProvider(props) {
  const [firstAttemptFinished, setFirstAttemptFinished] = React.useState(false)
  const [user, setUser] = useState(null)
  const [serverDown, setServerDown] = useState(false)

  const initialize = async () => {
    const {user, serverError} = await authClient.getUser()
    setUser(user)
    setServerDown(serverError)
    setFirstAttemptFinished(true)
  }

  useEffect(() => {
    initialize()
  }, [])

  const login = React.useCallback(
    form => authClient.login(form).then(user => setUser(user)),
    [setUser],
  )
  const logout = React.useCallback(() => {
    authClient.logout()
    setUser(null)
  }, [setUser])

  const register = React.useCallback(
    ({token, password}) =>
      authClient.register({token, password}).then(user => setUser(user)),
    [setUser],
  )

  const activate = React.useCallback(
    id => authClient.activate(id).then(user => setUser(user)),
    [setUser],
  )

  const value = React.useMemo(
    () => ({user, login, logout, setUser, activate, register, serverDown}),
    [login, logout, user, setUser, activate, register, serverDown],
  )

  if (!firstAttemptFinished) return <FullPageSpinner />

  return <AuthContext.Provider value={value} {...props} />
}

function useAuth() {
  const context = React.useContext(AuthContext)
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`)
  }
  return context
}

export { AuthContext, AuthProvider, useAuth };

