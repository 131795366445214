import React from 'react';
import {colors} from 'styles/theme'

export const roles = {
  RESEARCH_ADMIN: 1,
  DISTRICT_ADMIN: 2,
  SCHOOL_ADMIN: 3,
  YOUTH: 4,
  HOME_SUPPORT: 5,
  SCHOOL_SUPPORT: 6,
  COMMUNITY_SUPPORT: 7,
}

Object.freeze(roles)

export const rolesToString = {
  [roles.RESEARCH_ADMIN]: 'research admin',
  [roles.DISTRICT_ADMIN]: 'district admin',
  [roles.SCHOOL_ADMIN]: 'school admin',
  [roles.HOME_SUPPORT]: 'home support',
  [roles.SCHOOL_SUPPORT]: 'school support',
  [roles.COMMUNITY_SUPPORT]: 'community support',
  [roles.YOUTH]: 'youth',
}

Object.freeze(rolesToString)

export const rolesToColor = {
  [roles.RESEARCH_ADMIN]: colors.fuschia,
  [roles.DISTRICT_ADMIN]: colors.lavender,
  [roles.SCHOOL_ADMIN]: colors.skyblue,
  [roles.HOME_SUPPORT]: colors.iris,
  [roles.SCHOOL_SUPPORT]: colors.evergreen,
  [roles.COMMUNITY_SUPPORT]: colors.pumpkin,
  [roles.YOUTH]: colors.iris,
}

Object.freeze(rolesToColor)

export const isAdmin = roleId =>
  [roles.RESEARCH_ADMIN, roles.DISTRICT_ADMIN, roles.SCHOOL_ADMIN].includes(
    roleId,
  )

export const statuses = {
  UNCONNECTED: 0,
  NOT_SENT: 1,
  NOT_STARTED: 2,
  IN_PROGRESS: 3,
  PENDING: 4,
  APPROVED: 5,
}

Object.freeze(statuses)

export const statusToString = {
  [statuses.UNCONNECTED]: 'unconnected',
  [statuses.NOT_SENT]: 'not sent',
  [statuses.NOT_STARTED]: 'not started',
  [statuses.IN_PROGRESS]: 'in progress',
  [statuses.PENDING]: 'pending',
  [statuses.APPROVED]: 'approved',
}

Object.freeze(statusToString)

export const preferredCommunication = {
  CALL: 1,
  TEXT: 2,
  EMAIL: 3,
}

Object.freeze(preferredCommunication)

export const preferredTime = {
  MORNING: 1,
  AFTERNOON: 2,
  EVENING: 3,
}

Object.freeze(preferredTime)
