import { useEffect, useRef } from 'react';

import dayjs from 'dayjs';

export const isObject = obj => obj !== null && typeof obj === 'object'

export const isEmpty = object =>
  !Object.values(object).some(x => x !== null && x !== '' && x !== undefined)

export const shallowCompare = (obj1, obj2) =>
  Object.keys(obj1).length === Object.keys(obj2).length &&
  Object.keys(obj1).every(
    key =>
      Object.prototype.hasOwnProperty.call(obj2, key) &&
      obj1[key] === obj2[key],
  )

// replace all string occurences in values of an object
export function replaceAll(entity, needle, replacement) {
  var newEntity = {},
    regExp = new RegExp(needle, 'g')
  for (var property in entity) {
    if (!Object.prototype.hasOwnProperty.call(entity, property)) {
      continue
    }

    var value = entity[property],
      newProperty = property

    if (typeof value === 'object') {
      value = replaceAll(value, needle, replacement)
    } else if (typeof value === 'string') {
      value = value.replace(regExp, replacement)
    }

    newEntity[newProperty] = value
  }

  return newEntity
}

export const useDidMountEffect = (func, deps) => {
  const didMount = useRef(false)
  useEffect(() => {
    if (didMount.current) func()
    else didMount.current = true
    // eslint-disable-next-line
  }, deps)
}

// format to be server friendly
// YYYY-MM-DD HH:MM:SS
export function formatDate(date) {
  return dayjs(date).format('YYYY-MM-DD HH:mm:ss')
}
